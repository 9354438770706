@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@600&family=Source+Serif+Pro:wght@300;400;700&display=swap');

html {
  border-top: 2px solid #d100e8;
}

body {
  font-family: "Source Serif Pro", serif;
  color: #000000;
  margin: 0;
}

span {
  display: inline-block;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.content a {
  display: inline-block;
  margin-top: 30px;
  position: relative;
}

.share a:after, .content a:after {
  height: 1px;
  width: 0;
  background-color: currentColor;
  display: block;
  position: absolute;
  bottom: -0.25rem;
  right: 0;
  left: auto;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  transition-duration: .5s;
  content: "";
}

.share a:hover:after, .content a:hover:after {
  left: 0;
  right: auto;
  width: 100%;
}

img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.odd img {
  margin-top: 60px;
  object-position: right;
}

.even img {
  margin-top: 60px;
  object-position: left;
}

h1 {
  font-family: 'Barlow Condensed', sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-size: 60px;
}

h2, p {
  font-weight: 300;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 160%;
  color: #333333;
}

.future p {
  color: #fff;
}

section.future {
  background: black;
}

section.future .odd, section.future .even {
  color: white;
  background: #000000;
}

h2 {
  font-size: 30px;
  font-weight: inherit;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.share {
  flex-wrap: wrap;
  padding-inline-start: 0;
  list-style: none;
  display: flex;
  gap: 30px;
  justify-content: center;
}

.share a, .content a {
  color: #d100e8;
}

.share li {
  position: relative;
}

.share li:first-child {
  margin-right: 30px;
}

.share li:first-child::after {
  right: -45px;
  bottom: 0;
  top: calc(50% - 1px);
  position: absolute;
  display: block;
  width: 30px;
  background: black;
  height: 1px;
  content: '';
}

.logo {
  max-width: 200px;
}

#go-to {
  margin-left: 20px;
  position: absolute;
  top: 30px;
  left: 30px;
  font-family: 'Barlow Condensed', sans-serif;
  color: #F57863;
  text-transform: uppercase;
}

#go-to::after {
  position: absolute;
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  background-image: url("arrow.svg");
  left: -20px;
  top: 4px;
}

.cube {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
}

.seperator {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  padding: 12px 0;
}

.seperator.reverse {
  transform: scaleX(-1);
}

.image h2 {
  color: #d100e8;
  overflow: hidden;
  font-weight: 600;
  font-size: 60px;
}

.seperator svg {
  fill: #000000;
  position: absolute;
  width: 12px;
  height: 12px;
}

.future .seperator svg {
  fill: white;
}

.seperator svg:first-child {
  top: 3px;
  right: -15px;
}

.seperator svg:last-child {
  bottom: 3px;
  left: -15px;
}

.seperator span {
  position: relative;
  margin: 0 auto;
  border: 3px solid black;
  width: calc(20% - 40px);
}

.future .seperator span {
  border: 3px solid white;
}

section.future:last-child div.seperator {
  display: none;
}

em {
  font-style: normal;
  background: yellow;
  padding-left: 8px;
  padding-right: 8px;
}

.read-more > span {
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #d100e8;
  font-family: 'Barlow Condensed', sans-serif;
}

.read-more > span i {
  position: relative;
  height: 14px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.read-more > span i svg {
  transform-origin: center;
  position: absolute;
  fill: #d100e8;
}

@media only screen and (max-width: 899px) {

  .logo {
    max-width: 150px;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    margin-bottom: 20px;
  }

  .image h2 {
    font-size: 50px;
  }

  section {
    overflow-x: hidden;
    padding: 0 30px;
  }

  #go-to {
    display: none;
  }

  .header {
    margin: 30px 0;
    padding: 30px;
  }
  
  .seperator span {
    width: calc(100% - 22px);
  }

  .odd, .even {
    position: relative;
    padding: 20px;
  }

  .image img {
    margin-bottom: 20px;
  }

  .image h2::before {
    background: white;
    transform: rotate(45deg) translateY(4px);
    position: absolute;
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border: 6px solid #000000;
    shape-rendering: crispEdges;
  }

  .future .image h2::before {
    border: 6px solid #fff;
    background: black;
  }

  .odd .image h2::before {
    transform: rotate(45deg) translateY(24px);
    left: 0;
  }

  .even .image h2::before {
    transform: rotate(45deg) translateY(24px);
    right: -34px;
  }

  .odd::before, .even::before {
    top: 0;
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    border-left: 6px solid #000000;
  }

  .odd::before {
    left: -10px;
  }

  .future .odd::before, .future .even::before {
    border-left: 6px solid #fff;
  }

  .odd .image, .even .image, .even {
    display: flex;
    flex-direction: column-reverse;
  }

  .even::before {
    right: -10px;
  }

  .even .image {
    text-align: right;
  }

  .share li:first-child {
    display: none;
  }

  .share {
    width: 100%;
    gap: unset;
    justify-content: space-between;
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.content p {
  margin-top: 30px;
}

@media only screen and (min-width: 900px) {

  .header {
    margin: calc(90px - 1em) auto;
    max-width: 1000px;
  }

  section > div, section > .seperator {
    max-width: 1000px;
    margin: 0 auto
  }

  .even, .odd {
    position: relative;
    display: flex;
    width: 100%;
  }

  .even {
    justify-content: flex-end;
  }

  .odd {
    justify-content: flex-start;
  }

  .even .image:before, .odd .content:before {
    background: white;
    transform: rotate(45deg) translate(15px, 15px);
    position: absolute;
    left: -15px;
    display: block;
    content: "";
    width: 12px;
    height: 12px;
    border: 6px solid #000000;
    shape-rendering: crispEdges;
  }
  
  section .even .image, section .odd .content {
    border-left: 6px solid #000000;
    padding-left: 10%;
  }

  .even .content, .odd .image {
    padding-right: 10%;
  }

  .content, .image {
    position: relative;
    padding: 60px 0;
  }

  section.future .even .image:before, section.future .odd .content:before {
    background: black;
    border: 6px solid #fff;
  }
  
  section.future .even .image, section.future .odd .content {
    border-left: 6px solid #fff;
  }

  .content {
    width: 60%;
    max-width: 400px;
  }

  .content h2 {
    font-weight: 400;
  }

  .image {
    width: 30%;
  }

  .odd .image {
    text-align: right;
  }
}

.read-more {
  overflow: hidden;
}